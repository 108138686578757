        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
              <div
                                class="row align-items-end"
                              >
                                  <div
                                          class="col-12 col-sm-auto"
                                        >
                                            <sp-select
  id="xparam1"
  name="xparam1"
    :value.sync="xparam1"
    label="Масштаб"
  />                  </div>
                                  <div
                                          class="col-12 col-sm-auto mb-1"
                                        >
                                            <home-button
  label="На верх"
    variant="outline-primary"
        classes="mr-50"
        />                  </div>
                                  <div
                                          class="col-12 col-sm-auto mb-1"
                                        >
                                            <forward-button
  label="Назад"
    variant="outline-primary"
        classes="mr-50"
      />                  </div>
                                  <div
                                          class="col-12 col-md-6"
                                        >
                                            <search-input
    :value.sync="qqq"
    api="https://api.hoglabest.com/api/v1/json/lk/buyer/search/list/struct"
  />                  </div>
                              </div>
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <tree-structure
    :xparam1="xparam1"
      :apiId="qqq"
                api="https://api.hoglabest.com/api/v1/htm/lk/buyer/binarthree/page"
/>            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                                    import SpSelect from '@core/components/input/select/SpSelect.vue'
                                                                        import HomeButton from '@core/components/button/HomeButton.vue'
                                                                        import ForwardButton from '@core/components/button/ForwardButton.vue'
                                                                        import SearchInput from '@core/components/input/search-input/SearchInput.vue'
                                                                          import TreeStructure from '@core/components/tree-structure/TreeStructure.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                                          SpSelect,
                                                                            HomeButton,
                                                                            ForwardButton,
                                                                            SearchInput,
                                                                                            TreeStructure,
                                          },
  setup() {
                const qqq = ref('')
            const xparam1 = ref('100%')
          
          return {
            qqq,
            xparam1,
            }
      },
}
</script>
    